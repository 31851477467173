export default {
  computed: {
    whGuest() {
      return this.$store.state.wannahost.wh_guest
    },
    whOpen() {
      return this.$store.state.wannahost.wh_open
    },
  },
  methods: {
    async checkWhOpen() {
      await this.$store.dispatch("wannahost/openWh", {
        value: true,
      })
    },
    async fetchWhCarts() {
      if (!this.whOpen) {
        return
      }
      try {
        if (this.whGuest) {
          await this.authWhProcess()
        }
        console.log(this.whGuest)
        console.log("fetch carts")
        await this.$store.dispatch("wh_cart/fetchTotal")
        await this.$store.dispatch("wh_cart/fetchCart")
      } catch (e) {
        console.log(e, "Error in handle")
      }
    },
    async authWhProcess() {
      await this.$store.dispatch("wannahost/auth")
    },
  },
}
